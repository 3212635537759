<template>
  <v-card>
    <map-radius :list="locationList"></map-radius>
  </v-card>
</template>

<script>
import MapRadius from "../components/MapRadius";
import { PORTAL_CONFIG } from "../config";

export default {
  name: "assets",
  components: { MapRadius },
  data() {
    return {
      locationList: []
    };
  },
  created() {
    this.loadData();
  },

  methods: {
    loadData() {
      if (this.getUserProfiles.includes(17)) {
        fetch(`${PORTAL_CONFIG.SERVER_WEB}/dev/where.php`)
          .then(response => {
            return response.json();
          })
          .then(result => {
            //this.locationList = result;
            if (this.$route.params.assetID === 31) {
              // permet de ne pas afficher les locations si il n'y a pas de coordonnées
              this.locationList = result.filter(el => el.id2 === 0);
            } else {
              this.locationList = result.filter(
                el => el.id2 === this.$route.params.assetID
              );
            }
          });
      }
    }
  },
  watch: {
    $route() {
      this.loadData();
    }
  }
};
</script>
